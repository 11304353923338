.game-border {
    margin: auto;
    margin-top: 20px;
    height: 384px;
    width: 320px;
    background-color: green;
    border: 1px solid green;
    border-radius: 10px;
    background-image: url("../../assets/images/taptheballpitch.png");
    background-size: contain;
}

.shoot-logo {
    width: 180px;
}

.tap-the-ball-play-modal {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 300px;
    width: 100%;
    height: 150px;
    padding: 0.5rem !important;
}

.tap-the-ball-results-modal {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 300px;
    width: 100%;
    height: 600px;
    padding: 0.5rem !important;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

.shoot-explain {
    font-size: 21px;
}

.hi-score-container {
    width: 300px;
    text-align: center;
    border: 1px solid black;
    border-radius: 6px;
}

.your-hi-score {
    height: 30px;
    width: 180px;
    font-size: 18px;
    padding-top: 5px;
    color: white;
    text-align: center;
    background-color: #e15554;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.current-score {
    height: 30px;
    width: 120px;
    font-size: 18px;
    padding-top: 5px;
    color: white;
    text-align: center;
    background-color: #4d9de0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.underline {
    padding-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid goldenrod;
}

.sign-in-text {
    margin-top: 40px;
    font-size: 20px;
    text-align: center;
}

.modal-hi-score-text {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
}

.hi-score-detail {
    color: rgb(224, 44, 17);
}

.play-anyway-button {
    height: 35px;
    width: 160px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.ok-button {
    height: 35px;
    width: 50px;
    margin: auto;
    margin-top: 10px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.tap-the-ball-row-left {
    background-color: gainsboro;
    color: black;
    border-top: 2px solid black;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.tap-the-ball-row-center {
    background-color: gainsboro;
    color: black;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.tap-the-ball-row-right {
    background-color: gainsboro;
    color: black;
    border-top: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.tap-the-ball-position {
    text-align: center;
}

.tap-the-ball-avatar-box {
    width: 40px;
}

.tap-the-ball-avatar {
    object-fit: cover;
    width: 100%;
    display: block;
}

.tap-the-ball-username {
    padding-left: 10px;
    font-size: 18px;
}

.tap-the-ball-nickname {
    font-size: 12px;
}

.tap-the-ball-nickname-no-player {
    color: rgb(224, 44, 17);
    font-size: 12px;
}

.tap-the-ball-score {
    text-align: center;
    padding-right: 5px;
}

.box-style {
    border-radius: 1000px;
    width: 64px;
    height: 64px;
    background-image: url("../../assets/images/taptheball.png");
    background-size: contain;
}

.animated {
    -webkit-animation-duration: 1.0s;
    animation-duration: 1.0s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes bounceOutOne {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes bounceOutOne {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
        background-color: yellowgreen;
    }
}

.bounceOutOne {
    -webkit-animation-name: bounceOutOne;
    animation-name: bounceOutOne;
}

@-webkit-keyframes bounceOutTwo {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
}

@keyframes bounceOutTwo {
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    to {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
        background-color: yellowgreen;
    }
}

.bounceOutTwo {
    -webkit-animation-name: bounceOutTwo;
    animation-name: bounceOutTwo;
}