.scorertable {
    border-radius: 8px;
    font-size: 20px;
}

.hidden {
    display: none;
}

table {
    border-spacing: 0 2px;
    width: 100%;
}

.scorer-header>th:nth-child(1),
.scorer-header>th:nth-child(5) {
    width: 38%;
    max-width: 125px;
}

.scorer-header>th:nth-child(3) {
    width: 4%;
    max-width: 13px;
}

.scorer-header>th:nth-child(2),
.scorer-header>th:nth-child(4) {
    width: 8%;
    max-width: 26px;
}

.scorertable tr:first-child {
    border-bottom: 3px solid black;
}

.scorertable td:nth-child(1),
.scorertable td:nth-child(8) {
    width: 9%;
    max-width: 26px;
}

.scorertable td:nth-child(2),
.scorertable td:nth-child(7) {
    width: 31%;
    max-width: 115px;
}

.scorertable td:nth-child(3),
.scorertable td:nth-child(6) {
    width: 4%;
    max-width: 10px;
}

.scorertable td:nth-child(4),
.scorertable td:nth-child(5) {
    width: 6%;
    max-width: 26px;
}

.small-chevron-close {
    display: inline-block;
    font-size: 10px;
    color: gainsboro;
    animation: chevronClose 0.5s forwards 0s ease;
}

.small-chevron-open {
    display: inline-block;
    font-size: 10px;
    color: gainsboro;
    animation: chevronOpen 0.5s forwards 0s ease;
}

.left-rounded {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.right-rounded {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.center-cell {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.scorer-home-row {
    background-color: #a54639;
}

.home-colored-row {
    background-color: #672820;
    border-bottom: 4px solid black;
    color: gainsboro;
}

.away-colored-row {
    background-color: #104f6b;
    border-bottom: 4px solid black;
    color: gainsboro;
}

.home-central-colored-row {
    background-color: #672820;
    border: 4px solid black;
    border-top: 1px solid black;
    border-left: none;
}

.away-central-colored-row {
    background-color: #104f6b;
    border: 4px solid black;
    border-top: 1px solid black;
    border-right: none;
}

.incrementing-score {
    font-size: 11px;
    text-align: center;
    color: gainsboro;
}

.bold {
    font-size: 12px;
    color: white;
}

.inline-block {
    display: inline-block;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.align-bottom {
    padding: 0px;
    vertical-align: bottom;
}

.time {
    display: inline-block;
    font-size: 8px;
    width: 12px;
    height: 12px;
    line-height: 15px;
    text-align: center;
    color: white;
}

.time-home {
    background-color: black;
    border-top-left-radius: 6px;
    border-bottom: 0px;
}

.time-away {
    background-color: black;
    border-top-right-radius: 6px;
    border-bottom: 0px;
}

.assist {
    font-size: 7px;
    color: gainsboro;
}

.assist-home-padding {
    padding-right: 5px;
}

.assist-away-padding {
    padding-left: 5px;
}

.avatar-left {
    object-fit: cover;
    width: 100%;
    min-height: 30px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 4px solid black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.avatar-right {
    object-fit: cover;
    width: 100%;
    min-height: 30px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 4px solid black;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.player-right {
    margin-left: 10px;
}

.player-left {
    margin-right: 10px;
}

.scorer-header {
    width: 100%;
}

.score-header {
    background-color: #000000;
    color: gainsboro;
    border-bottom: 1px solid gainsboro;
    border-left: none;
    border-right: none;
    font-size: 23px !important;
}

.hometeam-header {
    height: 35px;
    color: gainsboro;
    background-color: #993b2f;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom: 1px solid #ced2d7;
    border-right-style: none;
}

.awayteam-header {
    height: 35px;
    color: gainsboro;
    background-color: #187199;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-bottom: 1px solid #ced2d7;
    border-left-style: none;
}

.header {
    font-size: 15px;
}

.player {
    display: inline-block;
    font-size: 14px;
}

.disable-text-select {
    user-select: none;
}

.rotate-chevron-home-assist {
    display: inline-block;
    transform: rotate(30deg);
    font-size: 6px;
    margin-left: 1px;
}

.rotate-chevron-away-assist {
    display: inline-block;
    transform: rotate(90deg);
    font-size: 6px;
    margin-right: 2px;
}

@media (min-width: 400px) {
    .header {
        font-size: 18px;
    }

    .player {
        font-size: 17px;
    }

    .assist {
        font-size: 9px;
    }

    .incrementing-score {
        font-size: 13px;
    }

    .bold {
        font-size: 14px;
    }

    .time {
        width: 14px;
        height: 14px;
    }
}

@media (min-width: 701px) and (max-width: 899px) {
    .app-home {
        grid-template-columns: repeat(2, 1fr);
    }

    .player {
        font-size: 14px;
    }

    .time {
        width: 14px;
        height: 14px;
    }

    .bold {
        font-size: 12px;
    }

    .incrementing-score {
        font-size: 11px;
    }

    .assist {
        font-size: 8px;
    }

    .rotate-chevron-home-assist {
        font-size: 7px;
        margin-left: 2px;
    }

    .rotate-chevron-away-assist {
        font-size: 7px;
        margin-right: 2px;
    }
}

@media (min-width: 900px) and (max-width: 1099px) {
    .app-home {
        grid-template-columns: repeat(2, 1fr);
    }

    .player {
        font-size: 19px;
    }

    .assist {
        font-size: 10px;
    }

    .bold {
        font-size: 13px;
    }

    .incrementing-score {
        font-size: 12px;
    }

    .time {
        font-size: 10px;
        width: 16px;
        height: 16px;
    }

    .rotate-chevron-home-assist {
        font-size: 8px;
        margin-left: 2px;
    }

    .rotate-chevron-away-assist {
        font-size: 8px;
        margin-right: 2px;
    }
}

@media (min-width: 1000px) and (max-width: 1199px) {
    .app-home {
        grid-template-columns: repeat(2, 1fr);
    }

    .player {
        font-size: 20px;
    }

    .assist {
        font-size: 12px;
    }

    .time {
        font-size: 10px;
        width: 16px;
        height: 16px;
    }

    .incrementing-score {
        font-size: 16px;
    }

    .bold {
        font-size: 17px;
    }

    .rotate-chevron-home-assist {
        font-size: 9px;
        margin-left: 2px;
    }

    .rotate-chevron-away-assist {
        font-size: 9px;
        margin-right: 2px;
    }
}

@media (min-width: 1200px) {
    .scorertable {
        width: 500px;
    }

    .scorerbody {
        max-height: calc(100vh - 150px);
        overflow-y: auto;
    }

    .header {
        font-size: 21px;
    }

    .player {
        font-size: 21px;
    }

    .assist {
        font-size: 11px;
    }

    .time-home {
        border-top-left-radius: 6px;
        border-bottom: 0px;
    }

    .time-away {
        border-top-right-radius: 6px;
        border-bottom: 0px;
    }

    .time {
        display: inline-block;
        font-size: 11px;
        width: 16px;
        height: 16px;
        line-height: 15px;
        text-align: center;
    }

    .player-right {
        margin-left: 12px;
        margin-bottom: 5px;
    }

    .player-left {
        margin-right: 12px;
        margin-bottom: 5px;
    }

    .rotate-chevron-home-assist {
        font-size: 7px;
        margin-left: 2px;
    }

    .rotate-chevron-away-assist {
        font-size: 7px;
        margin-right: 2px;
    }
}