.disable-text-select {
    user-select: none;
}

.text-align-center {
    text-align: center;
}

.option {
    min-height: 52px;
    font-size: 25px;
    color: rgb(49, 81, 92);
}

.error {
    margin-left: 20px;
    font-size: 11px;
    color: red;
}

.error-no-margin {
    font-size: 11px;
    color: red;
}

.center-error {
    font-size: 11px;
    color: red;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-minus-10 {
    margin-top: -10px;
}

.margin-top-minus-15 {
    margin-top: -15px;
}

.separator {
    border-bottom: 2px solid goldenrod;
    margin-bottom: 15px;
}

.chevron-collapsed {
    display: inline-block;
    margin-right: 10px;
    color: goldenrod;
    animation: chevronCollapsed 0.5s forwards 0s ease;
}

@keyframes chevronCollapsed {
    0% {
        transform: rotate(120deg);
    }

    100% {
        transform: rotate(30deg);
    }
}

.chevron-expanded {
    display: inline-block;
    margin-right: 10px;
    color: goldenrod;
    animation: chevronExpanded 0.5s forwards 0s ease;
}

@keyframes chevronExpanded {
    0% {
        transform: rotate(30deg);
    }

    100% {
        transform: rotate(120deg);
    }
}

.label {
    margin-left: 20px;
    font-size: 11px;
}

.sign-out-button {
    height: 35px;
    width: 120px;
    margin-top: 30px;
    margin-bottom: 18px;
    font-size: 21px;
    color: white;
    background-color: rgb(235, 90, 68);
    text-align: center;
    border-radius: 6px;
}

.user-box {
    margin-top: 30px;
    margin-bottom: 18px;
    padding-bottom: 20px;
    min-width: 240px;
    max-width: 300px;
    width: 100%;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.textbox {
    margin-left: 18px;
    padding-left: 10px;
    min-width: 220px;
    max-width: calc(100% - 50px);
    width: 100%;
    height: 38px;
    font-size: 18px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.select-box {
    margin-left: 18px;
    min-width: 220px;
    max-width: calc(100% - 40px);
    width: 100%;
    height: 38px;
    font-size: 18px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.select-option {
    font-family: sans-serif;
}

.sign-in-button {
    height: 35px;
    width: 150px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-sign-in-button {
    height: 35px;
    width: 150px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #8c8c8c;
    text-align: center;
    border-radius: 6px;
}

.create-account-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    font-size: 20px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-create-account-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    font-size: 20px;
    color: rgb(209, 209, 209);
    background-color: #8c8c8c;
    text-align: center;
    border-radius: 6px;
}

.check-email-verification {
    height: 140px;
    width: 260px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: black;
    background-color: #feff93;
    text-align: center;
    border-radius: 6px;
}

.link-player-pending {
    height: 100px;
    width: 260px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: black;
    background-color: #feff93;
    text-align: center;
    border-radius: 6px;
}

.link-player-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-link-player-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: white;
    background-color: #8c8c8c;
    text-align: center;
    border-radius: 6px;
}

.bold-text {
    font-weight: bold;
    margin-bottom: 10px;
}

.small-text {
    margin-top: 10px;
    font-size: 15px;
}

.coming-soon {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 21px;
    color: rgb(235, 90, 68);
}

.user-player-avatar-container {
    height: 100px;
    width: 100px;
}

.user-player-avatar {
    height: 100%;
    width: 100%;
}

.user-player-avatar-image {
    width: 85%;
    height: 85%;
    margin-left: 10%;
    margin-top: 10%;
    border: 5px solid goldenrod;
    border-radius: 1000px;
    /* circle */
    background-color: red;
}

.avatar-editor {
    width: 270px;
    margin-top: 15px;
    margin-left: 15px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.save-avatar-image-button {
    height: 35px;
    width: 120px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.change-avatar-container {
    width: 160px;
    margin: auto;
}

.change-avatar-button {
    height: 35px;
    width: 160px;
    margin: auto;
    margin-top: 15px;
    font-size: 18px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-change-avatar-button {
    height: 35px;
    width: 160px;
    margin: auto;
    margin-top: 15px;
    font-size: 18px;
    color: white;
    background-color: rgb(235, 90, 68);
    text-align: center;
    border-radius: 6px;
}

.save-edit-button {
    height: 35px;
    width: 120px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-save-edit-button {
    height: 35px;
    width: 120px;
    margin: auto;
    margin-top: 20px;
    font-size: 16px;
    color: white;
    background-color: rgb(235, 90, 68);
    text-align: center;
    border-radius: 6px;
}

.cancel-avatar-edit-button {
    height: 35px;
    width: 120px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: rgb(235, 90, 68);
    text-align: center;
    border-radius: 6px;
}

.dob-picker-box {
    margin-left: 18px;
    min-width: 220px;
    max-width: calc(100% - 40px);
    width: 100%;
    height: 38px;
    font-size: 18px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.dob-picker {
    padding-left: 10px;
    padding-right: 10px;
    min-width: 220px;
    width: 100%;
    height: 38px;
}

.reCaptcha-container {
    margin-left: 18px;
    padding-left: 10px;
    min-width: 220px;
    max-width: calc(100% - 50px);
    width: 100%;
    height: 150px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.reCaptcha-input {
    margin-top: 20px;
    padding-left: 10px;
    min-width: 100px;
    max-width: calc(100% - 100px);
    width: 100%;
    height: 30px;
    font-size: 18px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.reCaptcha-verify-button {
    height: 34px;
    width: 70px;
    margin: auto;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

#reload_href {
    font-size: 15px;
    padding-left: 25px;
}

#canv {
    border: 2px solid black;
    border-radius: 6px;
}

.forgot-password-text {
    margin-top: 10px;
    font-size: 12px;
    color: blue;
}

.reset-email-text {
    margin-top: 10px;
    font-size: 18px;
}

.top-border-separator {
    margin: 20px 10px 0 10px;
    border-top: 2px solid goldenrod;
}

.reset-password-button {
    height: 34px;
    width: 160px;
    margin: auto;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-reset-password-button {
    height: 34px;
    width: 160px;
    margin: auto;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    background-color: #8c8c8c;
    text-align: center;
    border-radius: 6px;
}

.reset-password-pending {
    height: 120px;
    width: 260px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
    color: black;
    background-color: #feff93;
    text-align: center;
    border-radius: 6px;
}