.App {
    text-align: center;
}

.cover {
    min-height: 100vh;
    background-attachment: fixed;
    background-image: linear-gradient(black, #5d8d15);
}

.App-body {
    max-width: 1600px;
    margin: 0 auto;
}

.hide-native-scrollbar {
    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
}
.hide-native-scrollbar::-webkit-scrollbar {
    /** WebKit */
    display: none;
}

input[type="file"] {
    display: none;
}
