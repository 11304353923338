.matchdate-container {
    width: 300px;
    height: 30px;
    font-size: 18px;
    font-weight: bolder;
    text-align-last: center;
    border-radius: 8px;
    border: 1px solid black;
    padding-left: 10px;
    background-color: gainsboro;
    color: black;
}

select:focus {
    outline: none;
}

.matchdate-option {
    width: 300px;
    height: 30px;
    font-size: 20px;
    border-radius: 8px;
    font-family: Lucida Console;
    background-color: gainsboro;
}
