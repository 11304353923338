.kickoff-box {
    border-top: 1px gainsboro solid;
    border-left: 1px gainsboro solid;
    border-right: 1px gainsboro solid;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgba(0, 0, 0, 0.9);
    width: 260px;
    text-align: center;
    margin: auto;
    font-size: 12px;
    color: gainsboro;
    margin-bottom: -2px;
}

.kick-off {
    width: 110px;
    text-align: center;
}
