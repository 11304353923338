.margin-top-5 {
    margin-top: 5px;
}

.league-grid {
    display: grid;
    grid-template-columns: 100px 200px;
    grid-template-rows: 100px;
    grid-template-areas: "lg1 lg2";
}

.league-container {
    width: 330px;
    height: 100px;
    border: 2px solid gainsboro;
    border-radius: 8px;
    position: relative;
    z-index: 1;
}

.league-icon {
    grid-area: lg1;
    width: 100px;
    height: 100px;
    border-radius: 6px;
}

.league-name-container {
    position: relative;
    height: 100px;
}

.league-name {
    grid-area: lg2;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 230px;
    text-align: center;
    color: gainsboro;
    text-shadow: -4px 2px 3px #000000;
}

.players-matches-grid {
    display: grid;
    grid-template-columns: 70px 230px;
    grid-template-rows: 70px;
    grid-template-areas: "pm1 pm2";
}

.players-matches-icon {
    grid-area: pm1;
    width: 70px;
    height: 70px;
    border-radius: 6px;
}

.players-matches-container {
    width: 330px;
    height: 70px;
    border: 2px solid gainsboro;
    border-radius: 8px;
}

.players-matches-text-container {
    width: 260px;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    height: 70px;
}

.players-matches-name {
    grid-area: pm2;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    width: 260px;
    text-align: center;
    color: gainsboro;
}

.inactive-flag {
    width: 55px;
    height: 15px;
    background-color: #3385a8;
    color: gainsboro;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.inactive-flag-position {
    position: absolute;
    left: 275px;
    z-index: 3;
}


.inactive-opacity {
    opacity: 0.45;
}