.resetPasswordModal {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 330px;
    width: 100%;
    height: 300px;
    padding: 0.5rem !important;
}

.reset-button {
    height: 34px;
    width: 160px;
    margin: auto;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.disabled-reset-button {
    height: 34px;
    width: 160px;
    margin: auto;
    margin-top: 20px;
    font-size: 17px;
    color: white;
    background-color: #8c8c8c;
    text-align: center;
    border-radius: 6px;
}

.password-match-error {
    font-size: 11px;
    color: red;
}
