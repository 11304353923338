.team-players {
    font-size: 20px;
}

.font-size {
    font-size: 20px;
}

.teamplayers-header {
    width: 100%;
}

.teamplayers-header>th {
    width: 50%;
    max-width: 115px;
}

.teamplayers-body {
    width: 100%;
}

.teamplayers-body>td:nth-child(1),
.teamplayers-body>td:nth-child(4) {
    width: 10%;
    max-width: 40px;
}

.teamplayers-body>td:nth-child(2),
.teamplayers-body>td:nth-child(3) {
    width: 40%;
    max-width: 132px;
}

.home-avatar {
    object-fit: cover;
    width: 100%;
    min-height: 40px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 4px solid black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.away-avatar {
    object-fit: cover;
    width: 100%;
    min-height: 40px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 4px solid black;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.home-row {
    background-color: #672820;
    color: gainsboro;
    border: 4px solid black;
    border-top: 1px solid black;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.away-row {
    background-color: #104f6b;
    color: gainsboro;
    border: 4px solid black;
    border-top: 1px solid black;
    border-right: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.disable-text-select {
    user-select: none;
}

.hide-player {
    background: transparent;
    border: none;
}

.left-players-header {
    background-color: gainsboro;
    height: 35px;
    font-weight: 600;
    color: black;
    border: 1px solid black;
    border-right: none;
    border-spacing: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.players-header {
    background-color: gainsboro;
    height: 35px;
    font-weight: 600;
    color: black;
    border: 1px solid black;
    border-right: none;
    border-left: none;
    border-spacing: 0px;
    text-align: center;
}

.right-players-header {
    background-color: gainsboro;
    height: 35px;
    font-weight: 600;
    color: black;
    border: 1px solid black;
    border-left: none;
    border-spacing: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

@media (min-width: 900px) and (max-width: 1199px) {
    .font-size {
        font-size: 25px;
    }
}