.icon-grayscale {
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
}

.icon {
    width: 90px;
    height: 90px;
    border-radius: 8px;
    display: inline-block;
}

.awardbox {
    width: 90px;
    height: 90px;
    display: inline-block;
    border-radius: 8px;
    margin: 10px;
}

.awardcount {
    width: 20px;
    height: 20px;
    background-color: #c0383f;
    color: white;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.awardbox-position {
    position: relative;
    z-index: 1;
}

.awardcount-position {
    position: absolute;
    top: 69px;
    left: 69px;
    z-index: 3;
}

.customModal {
    background: black !important;
    border-radius: 8px;
    border: 1px solid #838891;
    color: white;
    width: 310px;
    padding: 0.5rem !important;
}

.closeIcon {
    display: none;
}

.award-grid {
    display: grid;
    grid-template-columns: 100px 190px;
    grid-template-rows: 30px 60px;
    grid-template-areas:
        "ag1 ag2"
        "ag1 ag3";
}

.award-modal-icon {
    grid-area: ag1;
    width: 90px;
    height: 90px;
    border-radius: 8px;
}

.award-modal-title {
    grid-area: ag2;
    font-size: 20px;
    color: goldenrod;
    border-bottom: 1px solid silver;
}

.award-modal-body {
    grid-area: ag3;
    padding-top: 5px;
}

.disable-text-select {
    user-select: none;
}

.disable-award {
    background: #000000d6 !important;
}

.disable-title {
    color: #686868;
    border-bottom: 1px solid #747474;
}

.disable-body {
    color: #5d5d5d;
}

@media (min-width: 380px) and (max-width: 409px) {
    .icon {
        width: 100px;
        height: 100px;
    }

    .awardbox {
        width: 100px;
        height: 100px;
    }

    .awardcount-position {
        top: 79px;
        left: 79px;
    }
}

@media (min-width: 410px) and (max-width: 449px) {
    .icon {
        width: 110px;
        height: 110px;
    }

    .awardbox {
        width: 110px;
        height: 110px;
    }

    .awardcount-position {
        top: 89px;
        left: 89px;
    }
}

@media (min-width: 450px) and (max-width: 699px) {
    .icon {
        width: 120px;
        height: 120px;
    }

    .awardbox {
        width: 120px;
        height: 120px;
    }

    .awardcount-position {
        top: 99px;
        left: 99px;
    }
}

@media (min-width: 700px) and (max-width: 759px) {
    .icon {
        width: 80px;
        height: 80px;
    }

    .awardbox {
        width: 80px;
        height: 80px;
    }

    .awardcount {
        font-size: 11px;
    }

    .awardcount-position {
        top: 59px;
        left: 59px;
    }
}

@media (min-width: 760px) and (max-width: 1019px) {
    .icon {
        width: 100px;
        height: 100px;
    }

    .awardbox {
        width: 100px;
        height: 100px;
    }

    .awardcount-position {
        top: 79px;
        left: 79px;
    }
}

@media (min-width: 1020px) {
    .awardbox {
        width: 290px;
        height: 100px;
    }
    .customModal {
        text-align: left;
        width: 280px;
    }

    .award-modal-body {
        font-size: 14px;
    }

    .award-grid {
        grid-template-columns: 100px 180px;
    }

    .award-modal-title {
        font-size: 18px;
    }
}
