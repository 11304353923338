.match-league-name {
    width: 100%;
    text-align: center;
    color: gainsboro;
    font-size: 16px;
    font-style: italic;
    margin-bottom: 10px;
}

.match {
    margin: 10px 10px 0px 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.loading-gif-container {
    text-align: center;
}

.loading-gif {
    width: 100px;
    margin-top: 20px;
}

.fit-content {
    height: fit-content;
}

.two-column-grid {
    display: grid;
    grid-template-columns: 48vw 48vw;
    grid-template-rows: auto;
    grid-template-areas: "tcg1 tcg2";
    margin-left: 2vw;
    margin-right: 2vw;
}

.score-teams {
    grid-area: tcg1;
}

.scorers-assists {
    grid-area: tcg2;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-top-30 {
    margin-top: 30px;
}

.padding-horizontal-3 {
    padding-left: 3px;
    padding-right: 3px;
}

@media (max-width: 699px) {
    .match {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
        max-width: 500px;
    }
}

@media (min-width: 1200px) {
    .match {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 1900px;
    }
}
