.App-header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-header th:nth-child(1),
.App-header th:nth-child(3) {
    width: 12%;
}

.App-header th:nth-child(2) {
    width: 76%;
}

.chevron-goback {
    color: gainsboro;
    transform: rotate(90deg);
    font-size: 20px;
}

.logo {
    height: 30px;
}
