.summary {
    font-size: 20px;
}

.stack-tables {
    display: flex;
    flex-direction: column;
}

.stack-tables table {
    width: 100%;
}

.hidden {
    display: none;
}

.top-border {
    border-radius: 8px;
}

.top-header {
    background-color: gainsboro;
    height: 35px;
    font-weight: 600;
    color: black;
    border-bottom: 2px solid black;
    border-spacing: 0px;
}

.top-left {
    width: 10%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid black;
    border-right: none;
}

.top-central {
    width: 80%;
    border: 1px solid black;
    border-left: none;
    border-right: none;
}

.top-right {
    width: 10%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid black;
    border-left: none;
}

.row {
    text-align: center;
}

.chevron-close {
    display: inline-block;
    color: rgb(100, 100, 100);
    animation: chevronClose 0.5s forwards 0s ease;
}

@keyframes chevronClose {
    0% {
        transform: rotate(60deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.chevron-open {
    display: inline-block;
    color: rgb(100, 100, 100);
    animation: chevronOpen 0.5s forwards 0s ease;
}

@keyframes chevronOpen {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(60deg);
    }
}

.summary-row-center {
    height: 65px;
    color: gainsboro;
    padding: 5px 20px 10px 20px;
    overflow: hidden;
}

.no-data-summary-row-center {
    height: 40px;
    color: gainsboro;
    overflow: hidden;
}

.summary-table {
    background-color: #00000075;
    display: block;
    overflow: hidden;
    margin-top: -2px;
    border-radius: 10px;
    margin-left: 2px;
    max-width: 99%;
    width: 100%;
}

.no-data-summary-table {
    background-color: #00000075;
    display: table;
    overflow: hidden;
    margin-top: -2px;
    border-radius: 10px;
    margin-left: 2px;
    max-width: 99%;
    width: 100%;
}

.summary-row {
    display: block;
    height: 63px;
}

.collapsed-height {
    max-height: 65px;
}

.summary-border {
    border: 1px solid black;
    border-bottom: 4px solid black;
    border-right: 4px solid black;
}

.disable-text-select {
    user-select: none;
}

.summary-font-size {
    font-size: 18px;
}