.top-player {
    font-size: 20px;
}

.hidden {
    display: none;
}

.top-border {
    border-radius: 8px;
}

.top-header {
    background-color: gainsboro;
    height: 35px;
    font-weight: 600;
    color: black;
    border-bottom: 2px solid black;
    border-spacing: 0px;
}

.top-left {
    width: 10%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid black;
    border-right: none;
}

.top-central {
    width: 80%;
    border: 1px solid black;
    border-left: none;
    border-right: none;
}

.top-right {
    width: 10%;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid black;
    border-left: none;
}

.row {
    text-align: center;
}

.chevron-close {
    display: inline-block;
    color: rgb(100, 100, 100);
    animation: chevronClose 0.5s forwards 0s ease;
}

@keyframes chevronClose {
    0% {
        transform: rotate(60deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.chevron-open {
    display: inline-block;
    color: rgb(100, 100, 100);
    animation: chevronOpen 0.5s forwards 0s ease;
}

@keyframes chevronOpen {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(60deg);
    }
}

.no-data-row-left {
    height: 40px;
    width: 12%;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid black;
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.no-data-row-center {
    height: 40px;
    width: 76%;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.no-data-row-right {
    height: 40px;
    width: 12%;
    color: black;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid black;
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.row-left {
    width: 12%;
    color: black;
}

.home-row-center {
    width: 68%;
    background-color: #672820;
    color: gainsboro;
    border-bottom: 4px solid black;
    border-top: 1px solid black;
}

.home-row-right {
    padding-right: 10px;
    width: 20%;
    background-color: #672820;
    color: gainsboro;
    border: 4px solid black;
    border-left: none;
    border-top: 1px solid black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.away-row-center {
    width: 68%;
    background-color: #104f6b;
    color: gainsboro;
    border-bottom: 4px solid black;
    border-top: 1px solid black;
    border-right: none;
}

.away-row-right {
    padding-right: 10px;
    width: 20%;
    background-color: #104f6b;
    color: gainsboro;
    border: 4px solid black;
    border-left: none;
    border-top: 1px solid black;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.top-avatar {
    object-fit: cover;
    width: 100%;
    min-height: 33px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: 1px;
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 4px solid black;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.disable-text-select {
    user-select: none;
}

.top-font-size {
    font-size: 22px;
}

@media (min-width: 900px) and (max-width: 1199px) {
    .top-font-size {
        font-size: 25px;
    }
}