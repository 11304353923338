.player-stats-league-name {
    width: 100%;
    font-size: 16px;
    text-align: center;
    font-style: italic;
    color: gainsboro;
}

.player-stats-container {
    margin-left: 20px;
    margin-right: 20px;
}

.player-stats-table {
    width: 100%;
}

.player-stats-no-data {
    width: 100%;
    height: 40px;
    font-size: 23px;
    padding-top: 15px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-radius: 6px;
}

.player-stats-index {
    width: 4%;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.player-stats-index-full {
    width: 3%;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.player-stats-avatar {
    width: 12%;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-left: none;
}

.player-stats-avatar-full {
    width: 5%;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-left: none;
}

.player-stats-avatar-icon {
    object-fit: cover;
    width: 100%;
    min-height: 33px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
}

.player-stats-player {
    width: 44%;
    padding-left: 10px;
    font-size: 20px;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-left: none;
}

.player-stats-player-full {
    width: 22%;
    padding-left: 10px;
    font-size: 26px;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-right: none;
    border-left: none;
}

.player-stats-stat {
    width: 40%;
    font-size: 16px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.right-align {
    text-align: right;
    padding-right: 15px;
}

.player-stats-stat-full {
    width: 10%;
    font-size: 20px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-left: none;
    border-right: none;
}

.player-stats-stat-full-end {
    width: 10%;
    font-size: 20px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-left: none;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.player-stats-fullname {
    float: left;
    font-size: 11px;
    color: rgb(157, 157, 157);
}

.player-ratio-change {
    height: 10px;
    margin: 0 4px 0 2px;
}

.player-stats-small-ratio {
    font-size: 12px;
}

.player-stats-less-than-10-apps {
    float: right;
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    color: gainsboro;
    background-color: #a54639;
    border-radius: 6px;
    margin-bottom: 2px;
}

.player-stats-inactive {
    float: right;
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    color: gainsboro;
    background-color: #3385a8;
    border-radius: 6px;
    margin-top: 4px;
}

.player-stats-top-row-blank {
    width: 52%;
}

.player-stats-top-row {
    width: 48%;
    height: 30px;
    font-size: 15px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(90, 90, 90, 0.6);
    border: 1px solid gainsboro;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.player-stats-top-row-custom {
    width: 48%;
    height: 30px;
    font-size: 13px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(90, 90, 90, 0.6);
    border: 1px solid gainsboro;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.player-stats-top-row-full {
    width: 10%;
    height: 50px;
    font-size: 15px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(0, 0, 0, 0.6);
    border: 1px solid gainsboro;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.player-stats-top-row-full-selected {
    width: 10%;
    height: 50px;
    font-size: 15px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(90, 90, 90, 0.6);
    border: 1px solid gainsboro;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.player-stats-top-row-date-full {
    width: 30%;
    height: 50px;
    font-size: 21px;
    text-align: center;
    color: gainsboro;
    background-color: rgba(90, 90, 90, 0.6);
    border: 1px solid gainsboro;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.float-right {
    float: right;
    padding-right: 5px;
}

.modal-stat {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 330px;
    width: 100%;
    height: 560px;
    padding: 0.5rem !important;
}

.modal-date {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 330px;
    width: 100%;
    padding: 0.5rem !important;
}

.modal-question {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    max-width: 250px;
    width: 100%;
    height: 130px;
    padding: 0.5rem !important;
}

.modal-date-height {
    height: 360px;
}

.modal-date-expanded-height {
    height: 490px;
}

.modal-stat-header {
    font-size: 28px;
    text-align: center;
    font-weight: bold;
}

.modal-question-header {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
}

.modal-question-text {
    text-align: center;
}

.modal-player-stats-option {
    height: 40px;
    padding-left: 10px;
    font-size: 20px;
    border-bottom: 1px solid goldenrod;
}

.modal-player-stats-custom {
    height: 40px;
    padding-left: 10px;
    font-size: 20px;
}

.modal-player-stats-custom-no-option {
    padding-left: 10px;
    border-bottom: 1px solid goldenrod;
}

.modal-player-stats-custom-option {
    height: 130px;
    padding-left: 10px;
    font-size: 20px;
    border-bottom: 1px solid goldenrod;
}

.date-picker-box {
    width: 150px;
    height: 38px;
    font-size: 18px;
    border: 1px solid rgb(49, 81, 92);
    border-radius: 6px;
}

.date-picker {
    padding-left: 10px;
    padding-right: 10px;
    width: 100px;
    height: 38px;
}

.date-picker-label {
    margin-left: 5px;
    font-size: 14px;
}

.display-inline-block {
    display: inline-block;
}

.full-width {
    width: 100%;
}

.date-picker-apply-button {
    height: 35px;
    width: 120px;
    margin: auto;
    margin-top: 20px;
    font-size: 21px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.margin-left-10 {
    margin-left: 10px;
}

.custom-date-chevron {
    margin-left: 10px;
}

.custom-date-error-text {
    margin-bottom: -19px;
    font-size: 14px;
    color: rgb(235, 90, 68);
}

.last-six-match-form {
    height: 15px;
    margin: 5px 1px 0 1px;
}

.active-player-switch {
    position: relative;
    display: inline-block;
    width: 53px;
    height: 27px;
}

.active-player-switch input {
    display: none;
}

.active-player-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a54639;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

.active-player-slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

input:checked+.active-player-slider {
    background-color: #5d8d15;
}

input:focus+.active-player-slider {
    box-shadow: 0 0 1px #5d8d15;
}

input:checked+.active-player-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.active-player {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 10px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    padding: 4px 6px 4px 4px;
}

.white-text {
    padding-left: 10px;
    color: gainsboro;
}

.question-mark-container {
    padding-top: 5px;
    margin-left: 10px;
}

.question-mark {
    height: 18px;
}

@media (min-width: 400px) and (max-width: 499px) {
    .last-six-match-form {
        height: 20px;
        margin: 5px 1px 0 1px;
    }
}

@media (min-width: 500px) and (max-width: 599px) {
    .last-six-match-form {
        height: 22px;
        margin: 5px 1px 0 1px;
    }

    .player-stats-player {
        font-size: 22px;
    }

    .player-stats-fullname {
        font-size: 13px;
    }

    .player-stats-stat {
        font-size: 18px;
    }

    .player-stats-small-ratio {
        font-size: 13px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .last-six-match-form {
        height: 25px;
        margin: 5px 2px 0 1px;
    }

    .player-stats-player {
        font-size: 25px;
    }

    .player-stats-fullname {
        font-size: 15px;
    }

    .player-stats-stat {
        font-size: 20px;
    }

    .player-stats-small-ratio {
        font-size: 15px;
    }
}

@media (min-width: 700px) and (max-width: 1199px) {
    .player-stats-top-row-full {
        width: 7%;
        height: 50px;
        font-size: 12px;
    }

    .player-stats-top-row-full-selected {
        width: 7%;
        height: 50px;
        font-size: 12px;
    }

    .player-stats-top-row-date-full {
        width: 30%;
        height: 50px;
        font-size: 18px;
    }

    .player-stats-index-full {
        width: 3%;
    }

    .player-stats-avatar-full {
        width: 4%;
    }

    .player-stats-less-than-10-apps {
        font-size: 10px;
        padding: 1px 5px 1px 5px;
    }
}

@media (min-width: 1200px) {
    .player-stats-top-row-full {
        width: 8%;
        height: 50px;
        font-size: 12px;
    }

    .player-stats-top-row-full-selected {
        width: 8%;
        height: 50px;
        font-size: 12px;
    }

    .player-stats-top-row-date-full {
        width: 20%;
        height: 50px;
        font-size: 18px;
    }

    .player-stats-index-full {
        width: 2%;
    }

    .player-stats-avatar-full {
        width: 4%;
    }

    .player-stats-less-than-10-apps {
        font-size: 11px;
        padding: 1px 5px 1px 5px;
    }
}

@media (min-width: 1300px) {
    .player-stats-player-full {
        width: 14%;
        font-size: 20px;
    }

    .player-stats-fullname {
        font-size: 13px;
    }

    .player-stats-stat-full {
        width: 8%;
        font-size: 17px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 16px;
    }

    .player-stats-small-ratio {
        font-size: 11px;
    }

    .player-ratio-change {
        height: 8px;
    }
}


@media (min-width: 700px) and (max-width: 799px) {
    .last-six-match-form {
        height: 5px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 23%;
        font-size: 18px;
    }

    .player-stats-fullname {
        font-size: 11px;
    }

    .player-stats-stat-full {
        width: 7%;
        font-size: 10px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 10px;
    }

    .player-stats-small-ratio {
        font-size: 8px;
    }

    .player-ratio-change {
        height: 5px;
    }
}

@media (min-width: 800px) and (max-width: 899px) {
    .last-six-match-form {
        height: 6px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 23%;
        font-size: 18px;
    }

    .player-stats-fullname {
        font-size: 11px;
    }

    .player-stats-stat-full {
        width: 7%;
        font-size: 12px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 12px;
    }

    .player-stats-small-ratio {
        font-size: 9px;
    }

    .player-ratio-change {
        height: 5px;
    }
}

@media (min-width: 900px) and (max-width: 999px) {
    .last-six-match-form {
        height: 7px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 23%;
        font-size: 19px;
    }

    .player-stats-fullname {
        font-size: 12px;
    }

    .player-stats-stat-full {
        width: 7%;
        font-size: 14px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 14px;
    }

    .player-stats-small-ratio {
        font-size: 10px;
    }

    .player-ratio-change {
        height: 7px;
    }
}

@media (min-width: 1000px) and (max-width: 1099px) {
    .last-six-match-form {
        height: 8px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 23%;
        font-size: 19px;
    }

    .player-stats-fullname {
        font-size: 13px;
    }

    .player-stats-stat-full {
        width: 7%;
        font-size: 16px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 16px;
    }

    .player-stats-small-ratio {
        font-size: 10px;
    }

    .player-ratio-change {
        height: 7px;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {
    .last-six-match-form {
        height: 10px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 23%;
        font-size: 19px;
    }

    .player-stats-fullname {
        font-size: 13px;
    }

    .player-stats-stat-full {
        width: 7%;
        font-size: 16px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 16px;
    }

    .player-stats-small-ratio {
        font-size: 10px;
    }

    .player-ratio-change {
        height: 7px;
    }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .last-six-match-form {
        height: 13px;
        margin: 5px 0px 0 1px;
    }

    .player-stats-player-full {
        width: 14%;
        font-size: 19px;
    }

    .player-stats-fullname {
        font-size: 13px;
    }

    .player-stats-stat-full {
        width: 8%;
        font-size: 16px;
    }

    .player-stats-stat-full-end {
        width: 8%;
        font-size: 16px;
    }

    .player-stats-small-ratio {
        font-size: 11px;
    }

    .player-ratio-change {
        height: 8px;
    }
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .last-six-match-form {
        height: 14px;
        margin: 5px 0px 0 1px;
    }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .last-six-match-form {
        height: 15px;
        margin: 5px 0px 0 1px;
    }
}

@media (min-width: 1500px) and (max-width: 1599px) {
    .last-six-match-form {
        height: 17px;
        margin: 5px 0px 0 1px;
    }
}

@media (min-width: 1600px) {
    .last-six-match-form {
        height: 18px;
        margin: 5px 0px 0 1px;
    }
}