.user-logo {
    height: 35px;
    width: 35px;
    margin-top: 5px;
    border-radius: 1000px; /* circle */
    background-color: #0787e0;
}

.user-logo-signedOut {
    border: 2px solid gainsboro;
}

.user-logo-signedIn {
    border: 2px solid goldenrod;
}

.disable-text-select {
    user-select: none;
}

.center-logo {
    text-align: center;
    padding-top: 3px;
    margin-bottom: -20px;
}

.userModal {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 330px;
    width: 100%;
    height: 620px;
    padding: 0.5rem !important;
}

.user-body {
    height: 560px;
    overflow-y: auto;
}

.italic-name {
    margin-bottom: 10px;
    font-style: italic;
}
