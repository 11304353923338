.linkPlayerModal {
    background: white !important;
    border-radius: 8px;
    border: 1px solid #838891;
    min-width: 260px;
    max-width: 330px;
    width: 100%;
    height: 160px;
    padding: 0.5rem !important;
}

.link-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    font-size: 20px;
    color: white;
    background-color: #3db7eb;
    text-align: center;
    border-radius: 6px;
}

.reject-button {
    height: 35px;
    width: 180px;
    margin: auto;
    margin-top: 20px;
    font-size: 20px;
    color: white;
    background-color: rgb(235, 90, 68);
    text-align: center;
    border-radius: 6px;
}

.center-align {
    text-align: center;
}

.error-text {
    color: rgb(235, 90, 68);
}
