.player-header {
    background-image: linear-gradient(rgb(53, 53, 53), black);
    border-radius: 8px;
    margin: 0px 10px 0px 10px;
    height: 33vw;
    position: relative;
}

.player-avatar {
    grid-area: hg1;
    height: 100%;
    width: 100%;
}

.player-avatar-image {
    width: 85%;
    height: 85%;
    margin-left: 10%;
    margin-top: 5%;
    border: 5px solid goldenrod;
    border-radius: 1000px;
    /* circle */
    background-color: #0787e0;
}

.player-stats {
    width: 50vw;
    max-width: 370px;
}

.nick-name {
    grid-area: hg2;
    height: 100%;
    padding-left: 8px;
    margin-top: 12px;
}

.nick-name-font {
    color: goldenrod;
    font-size: calc(11vw);
}

.golden {
    color: goldenrod;
}

.last-match {
    position: absolute;
    top: 3px;
    right: 7px;
    color: gainsboro;
    text-align: right;
    font-size: calc(2.5vw);
}

.inactive-player {
    font-size: 11px;
    padding-left: 5px;
    padding-right: 5px;
    color: gainsboro;
    background-color: #3385a8;
    border-radius: 6px;
    margin-left: 12px;
}

.full-name {
    height: 100%;
    grid-area: hg3;
    padding-left: calc(4vw);
}

.full-name-font {
    font-size: calc(4.5vw);
    color: gainsboro;
    font-style: italic;
}

.nat-age-join {
    grid-area: hg4;
    color: white;
    padding-left: 5px;
}

.nat-age-join-font {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: calc(3vw);
}

.flag-container {
    margin-right: 10px;
}

.header-grid {
    height: 100%;
    display: grid;
    grid-template-columns: 33vw 66vw;
    grid-template-rows: 50% 21% 29%;
    grid-template-areas:
        "hg1 hg2"
        "hg1 hg3"
        "hg1 hg4";
}

.stats-trophies-grid {
    margin: 20px 10px 10px 10px;
    display: grid;
    align-items: center;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50px;
    grid-template-areas: "st1 st2";
}

.stats-header {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 15px;
    text-align: center;
    grid-area: st1;
    border: 2px rgba(0, 0, 0, 0.5) solid;
}

.awards-header {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 15px;
    text-align: center;
    grid-area: st2;
    border: 2px rgba(0, 0, 0, 0.5) solid;
}

.header-selected {
    background-color: gainsboro;
    font-size: 23px;
}

.header-unselected {
    background-color: rgb(155, 155, 155);
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin: 10px;
}

.age-span {
    color: goldenrod;
    margin-right: 6px;
}

.joined-span {
    color: goldenrod;
    margin-left: 10px;
    margin-right: 6px;
}

.stats-table {
    margin: 10px;
    font-size: 20px;
    border-collapse: collapse;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.76);
    color: gainsboro;
}

.ratio {
    font-size: 10px;
}

.ratio-change {
    height: 12px;
    margin: 0 4px 0 2px;
}

.stats-table tr {
    height: 40px;
}

.stats-table td {
    padding-left: 20px;
    border-bottom: 1px solid rgb(160, 120, 19);
}

.stats-table td:nth-child(1) {
    font-size: 15px;
    width: 60%;
}

.stats-table td:nth-child(2) {
    width: 40%;
    font-size: 18px;
    text-align: end;
    padding-right: 20px;
}

.awards-table {
    text-align: center;
    width: 100%;
    margin: 0px 10px 0px 10px;
    font-size: 20px;
    border-collapse: collapse;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.disable-text-select {
    user-select: none;
}

.center-right {
    display: flex;
    align-items: center;
    justify-content: right;
}

.most-assisted-container {
    min-width: 150px;
    height: 30px;
    background-color: black;
    border: 1px solid white;
    border-radius: 6px;
    font-size: 16px;
    margin-right: -3px;
}

.most-assisted-left {
    width: 30px;
}

.most-assisted-center {
    width: 90px;
    text-align: left;
    padding-left: 5px;
}

.most-assisted-right {
    width: 30px;
    padding-right: 5px;
}

.most-assisted-avatar {
    object-fit: cover;
    width: 30px;
    height: 30px;
    display: block;
    margin-top: -1px;
    margin-bottom: -1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.float-left {
    float: left;
}

.float-right-no-padding {
    float: right;
}

.form {
    height: 22px;
    margin: 0 2px 0 2px;
}

.faded-form-max {
    opacity: 0.20;
}

.faded-form-medium {
    opacity: 0.5;
}

.faded-form-min {
    opacity: 0.75;
}

@media (min-width: 700px) {
    .player-grid {
        height: 100%;
        display: grid;
        grid-template-columns: 380px auto;
        grid-template-rows: 120px auto;
        grid-template-areas:
            "pg1 pg3"
            "pg2 pg3";
    }

    .player-header {
        grid-area: pg1;
        width: 50vw;
        max-width: 370px;
        height: 120px;
    }

    .full-name {
        padding-left: 20px;
    }

    .awards-table {
        text-align: left;
        padding-bottom: 100px;
    }

    .player-stats {
        grid-area: pg2;
    }

    .player-awards {
        grid-area: pg3;
        max-width: calc(100vw - 400px);
        height: 90vh;
        overflow: auto;
        -webkit-mask-image: linear-gradient(to bottom,
                black 95%,
                transparent 100%);
        mask-image: linear-gradient(to bottom, black 95%, transparent 100%);
    }

    .header-grid {
        width: 100%;
        grid-template-columns: 33% 66%;
    }

    .player-avatar-image {
        height: 100px;
    }

    .nick-name-font {
        font-size: 36px;
    }

    .last-match {
        font-size: 12px;
    }

    .full-name-font {
        font-size: 16px;
    }

    .nat-age-join-font {
        font-size: 12px;
    }
}